var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.activeChannels,"loading":_vm.loading,"hide-default-footer":"","items-per-page":10000},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.uuid},[_c('td',[_c('channel-avatar',{attrs:{"channel":item.channel,"size":22}})],1),_c('td',[_c('a',{attrs:{"href":'https://www.instagram.com/' + item.username,"target":"_blank"}},[_vm._v(" "+_vm._s(item.username)+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(_vm._s(_vm._f("friendly_numbers")(item.followers)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("local_numbers")(item.followers)))])])],1),_c('td',[_vm._v(_vm._s(_vm._f("local_numbers")(item.media_count)))]),_c('td',[_vm._v(_vm._s(_vm._f("utc_to_local_datetime")(item.channel.updated_at)))]),_c('td',[(item.api_failed_at)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error"}},on),[_vm._v("fal fa-times")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("utc_to_local_datetime")(item.api_failed_at)))])]):_c('v-icon',{attrs:{"color":"success"}},[_vm._v("fal fa-check")])],1),_c('td',[_vm._v(_vm._s(item.active ? "Ja" : "Nej"))]),(item.channel.approved_at)?_c('td',[_vm._v(" "+_vm._s(_vm._f("utc_to_local_datetime")(item.channel.approved_at))+" ")]):_vm._e(),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","outlined":"","icon":""},on:{"click":function($event){_vm.selectedInfluencerChannel = item}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-chart-line")])],1)]}}],null,true)},[_c('span',[_vm._v("Se følger data")])])],1)])}),0)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1),_c('influencer-channel-data-dialog',{model:{value:(_vm.selectedInfluencerChannel),callback:function ($$v) {_vm.selectedInfluencerChannel=$$v},expression:"selectedInfluencerChannel"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
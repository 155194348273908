<template>
  <div v-if="influencer.user">
    <v-container fluid grid-list-xl class="p-0">
      <v-layout wrap>
        <v-flex xs12 sm12 md3>
          <v-card>
            <div>
              <v-card-title class="p-0">
                <v-spacer></v-spacer>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      icon
                      class="mr-3"
                      @click="showDeleteUserDialog = true"
                    >
                      <v-icon color="primary" style="color: #ffffff">
                        fal fa-user-slash
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-card-title>

              <div class="mt-2 px-4" style="display: flex; flex-wrap: wrap; gap: 0.75rem;">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" @click="setShadowLogin()" style="flex: 1 1 0%;">
                      <v-icon color="primary" style="color: #ffffff">
                        fal fa-user-secret
                      </v-icon>

                      <span class="ml-2">Shadow login</span>
                    </v-btn>
                  </template>
                  <span>Shadow login</span>
                </v-tooltip>
              </div>

              <div class="text-center pt-3">
                <user-avatar
                  :user="influencer.user"
                  class="display-1"
                  text-color="white--text"
                  :size="120"
                ></user-avatar>
              </div>

              <v-card-title>
                <div>
                  <div
                    style="color: #ffffff"
                    class="headline white-color-btn font-weight-bold primary--text"
                  >
                    {{ influencer.user.first_name }}
                    {{ influencer.user.last_name }}
                  </div>
                </div>
              </v-card-title>
            </div>
            <v-list two-line class="pa-0">
              <v-list-item :href="'mailto:' + influencer.user.email">
                <v-list-item-action>
                  <v-icon>fal fa-envelope</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    influencer.user.email
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item :href="'tel:' + influencer.user.phone">
                <v-list-item-action>
                  <v-icon>fal fa-phone</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    influencer.user.phone
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-birthday-cake</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    influencer.birthday | date_to_age
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-icon>fal fa-transgender</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{
                    influencer.gender === "MALE" ? "Mand" : "Kvinde"
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>

          <create-transaction-dialog
            v-if="showCreateTransactionDialog"
            v-model="showCreateTransactionDialog"
            :influencer="influencer"
          ></create-transaction-dialog>

          <v-card class="mt-4">
            <v-card-title>
              Commission
              <br />
              <v-btn
                color="primary"
                x-small
                text
                @click="showCreateTransactionDialog = true"
              >
                Add commission
              </v-btn>
            </v-card-title>
            <v-card-text class="p-1">
              <v-list subheader two-line>
                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon>fal fa-certificate</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>New</v-list-item-title>
                    <v-list-item-subtitle>{{
                      comissionStatuses.new
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon>fal fa-badge-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Approved</v-list-item-title>
                    <v-list-item-subtitle>{{
                      comissionStatuses.approved
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon>fal fa-receipt</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Invoiced (Not paid)</v-list-item-title>
                    <v-list-item-subtitle>{{
                      comissionStatuses.invoiced
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon>fal fa-hourglass</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Awaiting transfer</v-list-item-title>
                    <v-list-item-subtitle>{{
                      comissionStatuses.pending_payout
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 sm12 md9>
          <influencer-tabs :influencer="influencer"></influencer-tabs>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog v-model="redirectLink" v-if="redirectLink" max-width="300px">
      <v-card>
        <v-card-title class="title">
          Bekræft handling
        </v-card-title>
        <v-card-text class="text-center">
          <v-btn color="primary" :href="redirectLink" target="_blank"
            >Log ind</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showDeleteUserDialog"
      v-model="showDeleteUserDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Delete influencer
        </v-card-title>
        <v-card-text>
          <div v-if="hasPayout">
            <v-alert type="warning">
              Influencer has received a payout and the delete is therefore a
              little different.
              <br /><br />
              We're deleting the information that we can, but due the danish
              law, we have to keep certain information about the payout
            </v-alert>
          </div>

          Please verify all commission statuses, and that it's ok that we delete
          the influencer. The commission will either be cancelled, or
          transferred to us.
          <br />

          <ul class="mb-3">
            <li>New: {{ comissionStatuses.new }}</li>
            <li>Approved: {{ comissionStatuses.approved }}</li>
            <li>Invoiced: {{ comissionStatuses.invoiced }}</li>
            <li>Pending payout: {{ comissionStatuses.pending_payout }}</li>
          </ul>

          <v-text-field
            v-model="confirmText"
            outlined
            placeholder="Type Confirm to unlock button"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" @click="showDeleteUserDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            text
            :disabled="confirmText !== 'Confirm'"
            @click="deleteUser()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import date_to_age from "@/helpers/filters/date_to_age";
import InfluencerTabs from "../Tabs/InfluencerTabs/InfluencerTabs";
import UserAvatar from "../../avatars/UserAvatar";
import CreateTransactionDialog from "../dialogs/CreateTransactionDialog";

export default {
  components: { CreateTransactionDialog, UserAvatar, InfluencerTabs },
  filters: {
    date_to_age,
  },
  data: () => ({
    activeTab: null,
    influencer: {},
    loginToken: {},
    showDeleteUserDialog: false,
    confirmText: null,
    redirectLink: null,
    showCreateTransactionDialog: false,
    comissionStatuses: {},
    hasPayout: false,
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
  },
  methods: {
    ...mapActions("core", [
      "getInfluencer",
      "getComissionStatus",
      "deleteInfluencerAccount",
    ]),
    ...mapActions("core/auth", ["shadowLogin"]),
    ...mapActions("influencer", ["getPayouts"]),
    getCommission() {
      const params = {
        influencer: true,
        influencer_uuid: this.$route.params.id,
      };
      this.getComissionStatus(params).then((items) => {
        if (items.data.length > 0) {
          this.comissionStatuses = items.data[0];
        }
      });
    },
    getPayoutStatus() {
      let payoutAccount = this.influencer.payout_accounts.filter(
        (item) => item.active
      );

      if (payoutAccount.length <= 0) {
        return;
      }

      const data = {
        limit: 1,
        payout_account_id: payoutAccount[0].uuid,
      };

      this.getPayouts(data).then((response) => {
        console.log(response.data);
        this.hasPayout = !!(response.data.length > 0);
      });
    },
    setShadowLogin() {
      this.shadowLogin({ user_uuid: this.influencer.user.uuid }).then(
        (response) => {
          this.loginToken = response.token;
          this.redirectLink = `${process.env.VUE_APP_V1_SHADOW_LOGIN_URL}?token=${response.token}&shadow=true`;
          console.log('redirectLink', this.redirectLink);
          // this.redirectLink = response.link;
        },
        () => {
          this.setSnackError("Kunne ikke shadow login");
        }
      );
    },
    deleteUser() {
      this.deleteInfluencerAccount({ uuid: this.influencer.uuid }).then(
        () => {
          this.setSnackSuccess("Slettet");
          this.showDeleteUserDialog = true;
          this.$router.push({ name: "influencers" });
        },
        () => {
          this.setSnackError("Failed");
        }
      );
    },
    loadInfluencer() {
      this.getCommission();

      this.getInfluencer({ uuid: this.$route.params.id }).then(
        (response) => {
          this.influencer = response;
          this.getPayoutStatus();
        },
        () => {
          this.$router.push({ name: "influencers" });
        }
      );
    },
  },
  created() {
    this.loadInfluencer();
  },
};
</script>
